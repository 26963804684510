.domain_value {
  font-size: 48px;
}

.domain_text {
  font-size: 48px;
}

.row_item {
  border-bottom: 1px solid #ccc;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .domain_value {
    font-size: 36px;
  }

  .domain_text {
    font-size: 36px;
  }
}


@media (max-width: 640px) {
  .domain_value {
    font-size: 24px;
  }

  .domain_text {
    font-size: 24px;
  }
}