.services .sub_text {
	font-size: .9em;
	line-height: 1.658;
}

.services a {
	color: #000;
}

.services a i {
	transition: all .5s ease;
}

.services a:hover i {
	transform: rotate(360deg);
}

.services .sub_text_container {
	width: 54%;
}

@media (min-width: 768px) and (max-width: 1024px) {
	.services .sub_text_container {
		width: 100%;
	}
}

@media (min-width: 1025px) and (max-width: 1386px) {
	.services .sub_text_container {
		width: 60%;
	}
}

@media (max-width: 640px) {
	.services .sub_text_container {
		width: 100%;
	}
}