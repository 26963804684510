.about {
	color: #F5F6F9;
    background: #000;
    min-height: 100vh;
}

.about .plus_text {
	line-height: 1.08;
	font-size: 24px;
}

.client_name {
	font-size: 28px;
}

.feature_value_container {
	height: 75px;
}

.feature_container {
	width: 89%;
    margin-right: 11%;
}

.value_text {
	font-size: 64px;
	line-height: 1;
	left: 0;
	bottom: 0;
	font-family: "PTMono-Regular"
}

.about .slide_title_tag {
	font-size: 18px;
	font-weight: 400;
	line-height: 1.0;
	color: #CBCCCF;
	font-family: 'Open Sans';
}

.info {
	bottom: 0;
	right: 0;
}

@media (min-width: 768px) and (max-width: 820px) {
	.value_text {
		font-size: 48px;
	}

	.feature_container {
		width: 100%;
    	margin-right: 0%;
	}
}

@media (min-width: 821px) and (max-width: 1440px) {
	.client_name {
		font-size: 18px;
	}

	.feature_container {
		width: 70%;
    	margin-right: 30%;
	}

	.value_text {
		font-size:48px;
		line-height: 1;
		bottom: 0;
	}

	.about .plus_text {
		font-size: 18px;
		line-height: 1.08;
	}

	.about .slide_title_tag {
		font-size: .88em;
		font-weight: 400;
		line-height: 1.0;
	}
}

@media (max-width: 640px) {
	.value_text {
		font-size:48px;
		line-height: 1;
		bottom: 0;
	}

	.about .plus_text {
		font-size: 18px;
		line-height: 1.08;
	}

	.about .slide_title_tag {
		font-size: .88em;
		font-weight: 400;
		line-height: 1.0;
	}

	.about .slide_title {
		font-size: 1.28em;
		font-weight: 600;
		line-height: 1.0;
	}
}