.border-dark {
	cursor: pointer;
	transition: all 1s ease;
	border: 1px solid #ccc;
  	border-radius: 5px;
}

.border-dark:hover {
	opacity: .8;
	transform: scale(.95);
}