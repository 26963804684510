@font-face {
	font-family: "Archivo";
	src: local("Archivo-Regular"),
	url("./Fonts/Archivo-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Archivo-Narrow";
	src: local("ArchivoNarrow-Regular"),
	url("./Fonts/ArchivoNarrow-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Open Sans";
	src: local("OpenSans"),
	url("./Fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "PTMono-Regular";
	src: local("PTMono-Regular"),
	url("./Fonts/PTMono-Regular.ttf") format("truetype");
}

.open_Sans {
	font-family: "Open Sans";
}

.PTMono-Regular {
	font-family: "PTMono-Regular";
}

.archivo {
	font-family: "Archivo";
}

body {
  margin: 0;
  font-family: 'Archivo';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.26em;
}

