.swiper {
  width: 100%;
  height: 100%;
  font-size: .8em;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  text-align: left;
}

.swiper-slide { height: auto; }

.swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next, .swiper-button-prev {
  color: #fff;
  background-color: #0D0D0D;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  opacity: .9;
}


