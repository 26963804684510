.teams {
  color: #fff;
  background-color: #3C415C;
  font-size: 24px;
}

.team-text {
	font-size: 24px;
  font-weight: 400;
  line-height: 1.356;
  font-family: "Open Sans";
}

.stretegy_text {
  font-family: 'Archivo';
  font-size: 48px;
  font-weight: 400;
}

.author_name {
	font-size: 48px;
  font-weight: 700;
  font-family: "Open Sans";
}

.author_designation {
	font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  font-family: "Open Sans";
}

@media (min-width: 768px) and (max-width: 1440px) {
  .teams {
    color: #fff;
    background-color: #3C415C;
    font-size: 18px;
  }

  .team-text {
   font-size: 18px;
  }

  .author_name {
	font-size: 36px;
  }

  .author_designation {
	font-size:24px;
  }
}

@media (max-width: 640px) {
  .teams {
    color: #fff;
    background-color: #3C415C;
    font-size: 18px;
  }

  .team-text {
   font-size: 18px;
  }

  .author_name {
	font-size: 24px;
  }

  .author_designation {
	font-size: 18px;
  }

  .stretegy_text {
    font-size: 24px;
  }
}