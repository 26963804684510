.box {
	display: flex;
	flex-wrap: wrap;
}

.thank_you_container {
	height: 400px;
	font-size: 1em;
	padding: 5%;
}

.thank_you_container i {
	font-size: 4em;
}

.dialog_content {
	font-size: .8em;
	position: relative;
}

.dialog_header {
	background-color: #F5F6F9;
}

.dialog_sub_heading {
	font-size: 24px;
}

.dialog_content {
	font-size: 15px;
	font-family: 'Open Sans';
}

.dialog_content_list {
	font-size: 15px;
	font-family: 'Open Sans'; 
	line-height: 1.8;
}

.dialog_close {
	position: absolute;
	top: 10px;
	right: 10px;
}

.dialog_tablet_view {
	background-color: #646363;
	color: #fff;
	padding: 6px 10px;
	border-radius: 5px;
	font-size: .7em;
	white-space: nowrap;
}

.dialog_field_name {
	width: 33.3%;
}

.serv {
	width: 66.7%;
}

.serv ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  font-size: 1em;
  font-family: 'Open Sans';
}

.serv ul li {
  list-style: none;
  flex: 0 0 48%;
  padding: 10px 10px;
  margin: 0 4px;
}

.serv ul li:not(:last-child) {
	border-bottom: 1px solid #ccc;
}

.sector_name {
	width: 20%;
}

.contactUsForm {
	font-size: .7em;
	color: #000;
	opacity: .7;
}

.contactUsForm label {
	padding-bottom: 6px;
}

.small_icon {
	font-size: .7em;
	padding: 0 3px;
}

.contact_us_logo {
	font-family: "Archivo-Narrow";
	font-size: 24px;
	color: #000;
}

@media (min-width:768px) and (max-width: 1440px) {
	.dialog_sub_heading {
		font-size: 18px;
	}

	.sector_name {
		width: 15%;
	}
}

@media (max-width: 640px) {
	.dialog_tablet_view {
		background-color: #646363;
		color: #fff;
		padding: 4px 4px;
		border-radius: 5px;
	}

	.serv {
		width: 100%;
	}

	.sector_name {
		width: 100%;
	}

	.dialog_sub_heading {
		font-size: 14px;
	}

	.serv ul {
	  display: block;
	  flex-wrap: wrap;
	  padding-left: 0;
	  font-size: 1em;
	  font-family: 'Open Sans';
	  width: 100%;

	}

	.dialog_field_name {
		width: 100%;
		display: flex;
	}

	
}
