a {
  text-decoration: none;
}

ul {
  margin-bottom: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgrey; 
}

.row > * {
  margin : 0px ;
}

.ax-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  outline: inherit;
}

.ax-button:focus {
  outline: none;
}

.font_600 {
  font-weight: 600;
}

.font_700 {
  font-weight: 700;
}

.gallery img {
  width: 50%;
  margin: 0 25%;
}

.row {
  margin-left : 0;
  margin-right: 0;
}

.container, .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 640px) {
  .gallery img {
    width: 80%;
    margin: 0 10%;
  }
}


