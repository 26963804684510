/*  import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

/* all similar content styling codes */
section {
    padding: 10px 0;
}

.max-width {
    max-width: 1300px;
    padding: 0 80px;
    margin: auto;
}

.about, .services, .teams {
    padding: 7%;
}

.about .about-content,
.services .serv-content,
.skills .skills-content,
.contact .contact-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

/* navbar styling */
.navbar1{
    position: absolute;
    width: 100%;
    z-index: 999;
    padding: 30px 0;
    transition: all 0.3s ease;
}

.navbar1.sticky{
    padding: 15px 0;
}

.navbar1 .max-width{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar1 .logo a {
    color: #000000;
    font-size: 40px;
    font-weight: 700;
    font-family: 'Archivo-Narrow';
    transition: .3s;
    display: inline-block;
}

.navbar1 .logo a span{
    color: #111;
    transition: all 0.3s ease;
}

.navbar1 .menu li{
    list-style: none;
    display: inline-block;
}

.navbar1 .menu li a {
    display: block;
    color: #000;
    font-size: 1.2em;
    font-weight: 400;
    margin-left: 25px;
    transition: color 0.3s ease;
    opacity: 0;
    animation: slideTop .5s ease forwards;
    animation-delay: calc(.2s * var(--i));
}

.navbar1 .menu li a:hover{
    color: #111;
}

/* menu btn styling */
.menu-btn{
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    display: none;
}

.scroll-up-btn{
    position: fixed;
    height: 45px;
    width: 42px;
    background: crimson;
    right: 30px;
    bottom: 10px;
    text-align: center;
    line-height: 45px;
    color: #fff;
    z-index: 9999;
    font-size: 30px;
    border-radius: 6px;
    border-bottom-width: 2px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
}

.scroll-up-btn.show{
    bottom: 30px;
    opacity: 1;
    pointer-events: auto;
}
.scroll-up-btn:hover{
    filter: brightness(90%);
}


/* home section styling */
.home{
    display: flex;
    background: url("../../assets/BG_Image.png") no-repeat center;
    height: 100vh;
    min-height: 500px;
    background-size: cover;
    background-attachment: fixed;
    opacity: 1;
    color: #000;
}

.home .max-width{
  width: 100%;
  display: flex;
}

.home .max-width .row{
  margin-right: 0;
}

.home .home-content a{
    display: inline-block;
    background: crimson;
    color: #fff;
    font-size: 25px;
    padding: 12px 36px;
    margin-top: 20px;
    font-weight: 400;
    border-radius: 6px;
    border: 2px solid crimson;
    transition: all 0.3s ease;
}

.home .home-content a:hover{
    color: crimson;
    background: none;
}


@keyframes slideTop {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

/* responsive media query start */
@media (min-width: 768px) and (max-width: 1380px) {
    .about .about-content .left img{
        height: 350px;
        width: 350px;
    }

    .sub_text {
        font-size: 13px; 
    }


    .navbar1 .menu li a {
        font-size: 18px;
    }
}

@media (max-width: 991px) {
    .max-width{
        padding: 0 50px;
    }
}

@media (max-width: 947px) {
    .menu-btn{
        display: block;
        z-index: 999;
    }
    .menu-btn i.active:before{
        content: "\f00d";
    }
    .navbar1 .menu{
        position: fixed;
        height: 100vh;
        width: 100%;
        left: -100%;
        top: 0;
        background: #111;
        text-align: center;
        padding-top: 80px;
        transition: all 0.6s ease;
    }
    .navbar1 .menu.active{
        left: 0;
    }
    .navbar1 .menu li{
        display: block;
    }
    .navbar1 .menu li a{
        display: inline-block;
        margin: 20px 0;
        font-size: 25px;
        color: #fff;
    }
    .home .home-content .text-2{
        font-size: 70px;
    }
    .home .home-content .text-3{
        font-size: 35px;
    }
    .home .home-content a{
        font-size: 23px;
        padding: 10px 30px;
    }
    .max-width{
        max-width: 930px;
    }
    .about .about-content .column{
        width: 100%;
    }
    .about .about-content .left{
        display: flex;
        justify-content: center;
        margin: 0 auto 60px;
    }
    .about .about-content .right{
        flex: 100%;
    }
    .services .serv-content .card{
        width: calc(50% - 10px);
        margin-bottom: 20px;
    }
    .skills .skills-content .column,
    .contact .contact-content .column{
        width: 100%;
        margin-bottom: 35px;
    }
}

@media (max-width: 690px) {
    .max-width{
        padding: 0 23px;
    }
    .home .home-content .text-2{
        font-size: 60px;
    }
    .home .home-content .text-3{
        font-size: 32px;
    }
    .home .home-content a{
        font-size: 20px;
    }
    .services .serv-content .card{
        width: 100%;
    }
}

@media (max-width: 500px) {

    .navbar1 .menu li a, .navbar1 .menu li a:hover {
        font-size: .9em;
    }

    .home .home-content .text-2{
        font-size: 50px;
    }

    .home .home-content .text-3{
        font-size: 27px;
    }

    .about .about-content .right .text,
    .skills .skills-content .left .text{
        font-size: 19px;
    }

    .contact .right form .fields{
        flex-direction: column;
    }

    .contact .right form .name,
    .contact .right form .email{
        margin: 0;
    }

    .right form .error-box{
       width: 150px;
    }

    .scroll-up-btn{
        right: 15px;
        bottom: 15px;
        height: 38px;
        width: 35px;
        font-size: 23px;
        line-height: 38px;
    }

    .about, .services, .teams {
        padding: 7% 2%;
    }
}
