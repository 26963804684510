.footer{
	background-color: #111;
    color: #fff;
    font-size: 28px;
    line-height: 2;
}

.h1_text {
	font-size: 120px;
	font-weight: 500;

}

.footer a {
	color: #fff;
}

.footer .mail {
	font-size: 28px;
}

.footer h2 {
    font-size: 64px;
    font-weight: 400;
    font-family: 'Archivo-Narrow';
}

.footer h4 {
	font-weight: normal;
	font-size: 18px;
}

.footer h4 a {
	font-weight: normal;
	font-size: 24px;
}

.footer .policy {
	font-size: 16px;
}

@media (min-width: 768px) and (max-width: 1440px) {
	.footer{
    	font-size: 14px;
    	line-height: 1.4;
	}

	.footer .mail {
		font-size: 1.8em;
	}

	.h1_text {
		font-size: 84px;
		text-align: center;
	}

	.footer h2 {
    	font-size: 48px;
    	font-weight: 700;
    	font-family: 'Archivo-Narrow';
	}

	.footer h4 {
		font-size: 1.3em;
	}

	.footer .policy {
		font-size: 16px;
	}
}

@media (max-width: 640px) {
	.footer{
    	font-size: 18px;
    	line-height: 1.4;
	}

	.h1_text {
		font-size: 64px;
		text-align: center;
	}

	.footer .mail {
		font-size: 24px;
	}


	.footer h2 {
    	font-size: 48px;
    	font-weight: 400;
    	font-family: 'Archivo-Narrow';
	}

	.footer h4 {
		font-size: 18px;
	}

	.footer h4 a {
		font-size: 16px;
	}

	.footer .policy {
		font-size: 16px;
	}
}




