h1 {
    font-size: 84px;
}

.home h1 {
	margin-top: 30%;
	font-weight: 400;
}

.sub_h1_heading {
	font-size: 64px;
}

.sub-text {
	font-size: 30px;
	font-weight: 400;
	line-height: 1.356;
}

h2 {
    font-size: 64px;
    font-weight: 400;
    line-height: 1.06;
}

h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.06;
}

h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
}

.work_with_us {
	padding-top: 10%;
	width: 50%;
	font-size: 24px;
	cursor: pointer;	
}

.work_with_us a {
	color: #000;
}

.work_with_us i {
	transition: all .5s ease;
}

.work_with_us:hover i {
	transform: rotate(360deg);
}

.scroll_icon {
	margin-top: 15%;
	animation: FloatImage 4s ease-in-out infinite;
    animation-delay: 2s, 3s;
}


@keyframes FloatImage {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-14px);
    }

     100% {
        transform: translateY(0);
        opacity: 1;
    }
}


@media (min-width: 768px) and (max-width: 1440px) {
	.work_with_us {
		padding-top: 7%;
		width: 55%;
		font-size: 18px;
	}

	h1 {
	    font-size: 64px;
	    font-weight: 400;
	}

	.sub_h1_heading {
		font-size: 36px;
	}

	h2 {
	    font-size: 48px;
	    line-height: 1.31;
	}

	h3 {
	    font-size: 24px;
	    font-weight: 700;
	    line-height: 1;
	}

	h4 {
    	font-size: 24px;
    	line-height: 1.075;
	}

	.feature_container {
		width: 54vw;
	}

	.sub-text {
    	font-size: 24px;
    	font-weight: 400;
    	font-family: 'Open Sans';
    	line-height: 1.356;
	}

	.scroll_icon {
		margin-top: 3%;
	}
}

@media (max-width: 640px) {
	.work_with_us {
		padding-top: 7%;
		width: 60%;
		font-size: 18px;
	}

	h1 {
	    font-size: 42px;
	}

	.sub_h1_heading {
		font-size: 24px;
	}

	h2 {
	    font-size: 36px;
	    font-weight: 400;
	    line-height: 1.1;
	}

	h3 {
	    font-size: 24px;
	    font-weight: 700;
	    line-height: 1;
	}

	h4 {
    	font-size: 18px;
    	font-weight: 700;
    	line-height: 1.075;
	}

	.feature_container {
		width: 100vw;
	}

	.sub-text {
    	font-size: 18px;
    	font-family: 'Open Sans';
    	line-height: 1.356;
	}
}
